<template>
  <page-view title="详情" left-arrow>
    <div class="notify-doc container">
      <div class="notify-doc__header">
        <h2 class="notify-doc__title">{{xxbt}}</h2>
        <p class="notify-doc__time">
          <span style="margin-right: 0.5em">系统公告</span>
          <span>{{fbsj}}</span></p>
      </div>
      <div class="notify-doc__content" v-html="xxnr" @click="imagePreview"></div>
    </div>
  </page-view>
</template>

<script>
import { getHlwFbxxByXxid } from '@/api/login'
import {ImagePreview} from "vant";
export default {
  name: 'NotifyDetail',
  data() {
    return {
      xxbt:'',
      xxnr:'',
      fbr :'',
      xxly:'',
      fbsj:''
    }
  },
  methods: {
    fetchData(xxid) {
      getHlwFbxxByXxid(xxid).then(res => {
        console.log(res);
        if (res.status && res.status == 400) {
          throw new Error(res.message);
        }
        if (res.data && res.data.length) {
          let data = res.data[0];
          this.xxbt = data.xxbt;
          this.xxnr = data.xxnr;
          this.fbr = data.fbr;
          this.fbsj = data.fbsj
        } else {
          throw new Error("信息不存在或已删除！");
        }
      }).catch(err => {
        this.$dialog.alert({
          title: "提示",
          message: "信息详情加载失败:"+ err.message
        }).then(() => {
          this.$router.back();
        });
      });
    },
    imagePreview(e){
      if(e.target.nodeName === 'IMG' || e.target.nodeName === 'img'){
        ImagePreview({
          images:[e.target.currentSrc]
        })
      }
    }
  },
  mounted() {
    let params = this.$route.params;
    if (params._id) {
      this.xxid = params._id;
      this.fetchData(params._id)
    }
  },
}
</script>

<style lang="scss" scoped>
   @import 'src/styles/variable.scss';
  .notify-doc__header {
    margin-bottom: 20px;
  }
  .notify-doc__title {
    margin: 16px 0;
    line-height: 1.6;
    font-size: 16px;
  }

  .notify-doc__time {
    font-size: 12px;
    color: $color-gray-300;
  }
  .notify-doc__content /deep/{

    p {
      font-size: 14px;
      line-height: 1.5;
      margin: 0;
      margin-bottom: 10px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
</style>